import { EmptyObject, MarkOptional } from '@crossbeam/types';

import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { crossbeamApi } from '@/api';
import { captureException } from '@/errors';
import { ls } from '@/local_storage';
import { initStore } from '@/stores/store-utils';
import {
  OfflinePartner,
  OldSentPartnershipProposal,
  OverlapCounts,
  Partner,
  PartnerTag,
  PartnerTagMap,
  PublicInviteOrg,
  ReceivedPartnershipProposal,
  SentPartnershipProposal,
  UpdateVisibilityPayload,
  isNewProposal,
  isOfflinePartner,
} from '@/types/partners';

import { useFlashesStore } from './FlashesStore';

export const usePartnersStore = defineStore('PartnersStore', () => {
  const partnerOrgs = ref<Partner[]>([]);
  const offlinePartners = ref<OfflinePartner[]>([]);
  const partnerOrgsLookup = ref<Record<string, Partner | OfflinePartner>>({});
  const proposalsSent = ref<SentPartnershipProposal[]>([]);
  const proposalsReceived = ref<ReceivedPartnershipProposal[]>([]);
  const overlapCounts = ref<OverlapCounts | EmptyObject>({});
  const partnersByTag = ref<PartnerTagMap>({});
  const partnerTags = ref<PartnerTag[]>([]);
  const partnerFilters = ref<Record<string, boolean>>({});
  const publicInviteOrg = ref<PublicInviteOrg>(null);
  const publicInviteCode = ref<string>('');
  const hasRestrictedVisibility = ref(false);

  const { addSuccessFlash, addErrorFlash } = useFlashesStore();

  const { error, ready, readySync, running, refresh } = initStore(
    async (opts = { useCache: false }) => {
      try {
        const publicInvite: { code: string; org: null } = ls.publicInvite.get();
        if (publicInvite) {
          setPublicInviteCode(publicInvite.code);
          setPublicInviteOrg(publicInvite.org);
        }

        const [
          { data: partnersResponse, error: partnersError },
          { data: partnerTagsResponse, error: partnerTagsError },
        ] = await Promise.all([
          crossbeamApi.GET('/v0.1/partners'),
          crossbeamApi.GET('/v0.1/partner-tags'),
        ]);
        if (partnersError) throw partnersError;
        if (partnerTagsError) throw partnerTagsError;
        // Only call this on store initialization, not on refreshes...
        if (opts.useCache) await refreshOverlapUsage();

        partnerTags.value = partnerTagsResponse?.items ?? [];
        partnerTagsResponse?.items.forEach((tag) => {
          if (!partnerFilters.value[String(tag.id)]) {
            partnerFilters.value[String(tag.id)] = false;
          }
        });

        // Set partners
        partnerOrgs.value =
          partnersResponse?.partner_orgs.sort((a, b) =>
            a.name.localeCompare(b.name),
          ) ?? [];
        offlinePartners.value =
          partnersResponse?.partner_orgs.filter(isOfflinePartner) ?? [];

        proposalsSent.value =
          partnersResponse?.proposals.map(formatOldStyleProposal) ?? [];

        proposalsReceived.value = partnersResponse?.proposals_received ?? [];
        partnerOrgsLookup.value = partnerOrgs.value.reduce<
          Record<number, Partner | OfflinePartner>
        >((orgMap, org) => {
          orgMap[org.id] = org;
          return orgMap;
        }, {});

        partnersByTag.value = partnerOrgs.value.reduce<
          Record<string, number[]>
        >((tagLookup, org) => {
          if (!org.tags) return {};
          org.tags.forEach((tag) => {
            const tagId = String(tag.id);
            if (!tagLookup[tagId]) tagLookup[tagId] = [];
            tagLookup[tagId].push(org.id);
          });
          return tagLookup;
        }, {});
      } catch (err) {
        captureException(err);
      }
    },
  );

  refresh({ useCache: true });

  // Methods
  function getPartnerOrgById(id: number, trimmed = true) {
    const partnerOrg = partnerOrgsLookup.value[id];
    if (!partnerOrg) return;
    return trimmed ? trimOrg(partnerOrg) : partnerOrg;
  }

  function getPartnerOrgByUuid(uuid: string, trimmed = true) {
    const partnerOrg = partnerOrgs.value.find(
      (partner) => partner.uuid === uuid,
    );
    if (!partnerOrg) return;
    return trimmed ? trimOrg(partnerOrg) : partnerOrg;
  }

  function getPartnersByTag(tagId: string) {
    return partnersByTag.value[tagId] || [];
  }

  function getPartnerTagById(id: string) {
    return partnerTags.value.find((tag) => tag.id === id);
  }

  function getProposalReceivedById(id: number) {
    return proposalsReceived.value.find((proposal) => proposal.id === id);
  }

  function getProposalSentById(id: number) {
    return proposalsSent.value.find((proposal) => proposal.id === id);
  }

  function addProposal(proposal: SentPartnershipProposal) {
    const proposalIds = proposalsSent.value.map((proposal) => proposal.id);
    if (!proposalIds.includes(proposal.id)) {
      proposalsSent.value.push(formatOldStyleProposal(proposal));
    }
  }

  async function refreshOverlapUsage() {
    const usageResponse = await crossbeamApi.GET(
      '/v0.1/partners/global/overlap-counts',
    );
    overlapCounts.value = usageResponse.data ?? {};
  }

  function setPublicInviteOrg(org: null) {
    publicInviteOrg.value = org;
  }

  function setPublicInviteCode(code: string) {
    publicInviteCode.value = code;
  }

  function updateFilters(tagId: string) {
    partnerFilters.value[tagId] = !partnerFilters.value[tagId];
  }

  function resetPartnerFilters() {
    Object.keys(partnerFilters.value).forEach((tag) => {
      partnerFilters.value[tag] = false;
    });
  }

  async function deleteTag(tagId: string) {
    await crossbeamApi.DELETE('/v0.1/partner-tags/{tag_id}', {
      params: {
        path: { tag_id: tagId },
      },
    });
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete partnerFilters.value[tagId];
    refresh();
  }

  async function revokeProposal(proposalId: number) {
    try {
      const { error } = await crossbeamApi.PUT('/v0.1/proposals/{id}/revoke', {
        params: {
          path: {
            id: proposalId,
          },
        },
      });
      if (error) throw error;
    } catch (err) {
      captureException(err);
    } finally {
      const newProposals = [...proposalsSent.value];
      proposalsSent.value = newProposals.filter(
        (proposal) => proposal.id !== proposalId,
      );
      refresh();
    }
  }

  async function declineProposal(proposalId: number) {
    try {
      const { error } = await crossbeamApi.PUT('/v0.1/proposals/{id}/decline', {
        params: {
          path: {
            id: proposalId,
          },
        },
      });
      if (error) throw error;
    } catch (err) {
      captureException(err);
    } finally {
      const newProposals = [...proposalsReceived.value];
      proposalsReceived.value = newProposals.filter(
        (proposal) => proposal.id !== proposalId,
      );
      refresh();
    }
  }

  async function refreshProposals() {
    try {
      const { data, error } = await crossbeamApi.GET(
        '/v0.1/partners/global/proposals-received',
      );
      if (error) throw error;
      proposalsReceived.value = data?.items ?? [];
    } catch (err) {
      captureException(err);
    }
  }

  async function fetchVisibility(partnerUuid: string) {
    try {
      const { data, error } = await crossbeamApi.GET(
        '/v0.1/partners/{uuid}/team-access',
        {
          params: {
            path: {
              uuid: partnerUuid,
            },
          },
        },
      );
      if (error) throw error;
      hasRestrictedVisibility.value =
        data?.roles.some((role) => !role.has_access) ?? false;
      return data;
    } catch (error) {
      captureException(error);
      addErrorFlash({
        message: 'There was a problem loading your roles access.',
      });
    }
  }

  async function updateVisibility(
    partnerUuid: string,
    payload: UpdateVisibilityPayload,
    handleSuccess?: () => void,
  ) {
    try {
      const { data, error } = await crossbeamApi.PUT(
        '/v0.1/partners/{uuid}/team-access',
        {
          params: {
            path: {
              uuid: partnerUuid,
            },
          },
          body: payload,
        },
      );
      if (error) throw error;
      hasRestrictedVisibility.value =
        data?.roles.some((role) => !role.has_access) ?? false;
      addSuccessFlash({
        message: 'Your changes have been saved!',
      });
      handleSuccess?.();
    } catch (error) {
      captureException(error);
      addErrorFlash({
        message: 'There was a problem saving your changes.',
      });
    }
  }

  const openDealsTotal = computed(
    () => overlapCounts.value?.overlap_usage?.total_open_deal_amount,
  );

  const hasPartners = computed(() => !!partnerOrgs.value.length);

  return {
    error,
    ready,
    readySync,
    running,
    refreshPartnersStore: refresh,
    partnerOrgs,
    offlinePartners,
    partnerOrgsLookup,
    proposalsSent,
    proposalsReceived,
    overlapCounts,
    partnersByTag,
    partnerTags,
    partnerFilters,
    publicInviteOrg,
    publicInviteCode,
    getPartnerOrgById,
    getPartnerOrgByUuid,
    getPartnersByTag,
    getPartnerTagById,
    getProposalReceivedById,
    getProposalSentById,
    openDealsTotal,
    hasPartners,
    hasRestrictedVisibility,
    fetchVisibility,
    updateVisibility,
    addProposal,
    setPublicInviteOrg,
    setPublicInviteCode,
    updateFilters,
    resetPartnerFilters,
    deleteTag,
    revokeProposal,
    declineProposal,
    refreshProposals,
    refreshOverlapUsage,
  };
});

// Converts old schema for partnership proposals to new data schema
export function formatOldStyleProposal(
  proposal: OldSentPartnershipProposal | SentPartnershipProposal,
): SentPartnershipProposal {
  if (isNewProposal(proposal)) {
    return proposal;
  }
  return {
    id: proposal.id,
    status: proposal.status,
    created_at: proposal.created_at,
    contact_name: proposal.contact.name,
    contact_email: proposal.contact.email,
    contact_company: proposal.contact.company,
    sending_user: proposal.sending_user,
    migrated_from_reveal: proposal.migrated_from_reveal,
  };
}

export const trimOrg = (
  org:
    | MarkOptional<Partner, 'tags' | 'users'>
    | MarkOptional<OfflinePartner, 'tags' | 'users'>,
) => {
  if (!org) return undefined;

  const trimmedOrg = { ...org };
  delete trimmedOrg.users;
  delete trimmedOrg.tags;

  return trimmedOrg;
};
