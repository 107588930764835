<template>
  <span>{{ resource.displayName }}</span>
  <BittsSelect
    v-model="selectedPermission"
    dropdown-class-name="permissions-visibility-selector"
    :dropdown-match-select-width="false"
    :options="options"
    :searchable="false"
    :use-disabled-options="true"
    class="w-1/2"
    @change="(perm) => setRolePermission(resource.name, perm)"
    :disabled="isViewOnly"
  >
    <template #option="{ option }">
      <BittsTooltip :mount-to-body="true">
        <div class="flex flex-col">
          <div class="text-neutral-text-strong flex items-center gap-4">
            <FontAwesomeIcon
              v-if="option.icon"
              :icon="option.icon"
              class="text-neutral-accent"
            />
            {{ option.display_name }}
          </div>
          <div class="population-sharing-visibility-selector__description">
            {{ option.description }}
          </div>
        </div>
        <template v-if="option.disabled" #title>
          {{
            `The “Partner Access” permission must be set to “Manage" to enable the highest "${resource.displayName}" permission for this role.`
          }}
        </template>
      </BittsTooltip>
    </template>
  </BittsSelect>
  <BittsModalTwo
    v-if="resource.name === 'partner-visibility'"
    :model-value="isOpenPartnerVisibilityModal"
    title="Are you sure?"
    variant="confirm"
    class="permissions-visibility-selector__confirm-modal"
    @closed="handleShowPartnerVisibilityModal"
    @action="handleSavePartnerVisibilityModal"
  >
    <div class="px-20 mb-40">
      <div class="text-center">{{ warningDescription }}</div>
      <div class="flex flex-col gap-8 mt-16 px-40">
        <div
          v-for="permName in Object.values(WARNING_PERMISSIONS)"
          :key="permName"
          class="flex items-center gap-8"
        >
          <BittsSvg :svg="'xRed'" class="" alt="x" />{{ permName }}</div
        >
      </div>
    </div>
  </BittsModalTwo>
</template>

<script setup lang="ts">
import {
  BittsModalTwo,
  BittsSelect,
  BittsSvg,
  BittsTooltip,
} from '@crossbeam/bitts';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { storeToRefs } from 'pinia';
import { computed, ref, toRef, watch } from 'vue';

import { useRoleDetail } from '@/composables/useRoleDetail';
import { TEMP_PARTNER_VISIBILITY } from '@/constants/feature_flags';
import {
  useBillingStore,
  useFeatureFlagStore,
  usePermissionsStore,
} from '@/stores';
import { Permission, PermissionLevel } from '@/types/permissions';

const { resource, roleId, viewOnly } = defineProps<{
  resource: {
    name: string;
    displayName: string;
  };
  roleId?: string;
  viewOnly?: boolean;
}>();

const { isViewOnlyMode, loading, permissionSet, role, saveRole } =
  useRoleDetail(
    toRef(() => roleId),
    toRef(() => viewOnly),
  );

const isPartnerVisibilityEnabled = computed(() => {
  return featureFlagStore.hasFeatureFlag(TEMP_PARTNER_VISIBILITY);
});

const billingStore = useBillingStore();
const permissionsStore = usePermissionsStore();
const featureFlagStore = useFeatureFlagStore();

const { isEnterpriseTier } = storeToRefs(billingStore);
const { permissionDetails, permissionGroups } = storeToRefs(permissionsStore);

const selectedPermission = ref<PermissionLevel | null>(null);

const isOpenPartnerVisibilityModal = ref(false);
const warningDescription = computed(
  () =>
    `By removing “Partner access - Manage" to ${role.value.name} role you will also be removing them access to the following permissions.`,
);
const WARNING_PERMISSIONS = {
  'manage:oauth-applications': 'Applications',
  'view:audit-log': 'Audit Log',
  'manage:data-sources': 'Data Sources',
  'manage:integrations': 'Integrations',
  'manage:organization': 'Organization',
  'manage:populations': 'Populations',
  'manage:sharing': 'Default Sharing',
};

const options = computed(() => {
  const partnerVisibilityPerm = role.value.permissions.find(
    (r) => r.resource === 'partner-visibility',
  );
  const hasManagePartnerVisibility = partnerVisibilityPerm?.level === 'manage';

  return permissionGroups.value[resource.name]?.map((i: Permission) => ({
    ...i,
    icon: i.level === 'no-access' ? ['fak', 'hidden'] : null,
    value: i.level,
    key: i.id,
    is_default: false,
    disabled:
      isPartnerVisibilityEnabled.value &&
      !hasManagePartnerVisibility &&
      Object.keys(WARNING_PERMISSIONS).includes(i.id),
  }));
});

watch(
  () => permissionSet.value,
  () => {
    selectedPermission.value = getPermissionLevel(resource);
  },
);

const isNotSupernode = computed(() => {
  /* This view only mode is only based on billing tier:
  a user who has only the read:roles permission does not fall into this category.
  Theoretically it could be extended to include them, but there is more work to
  that than just editing the following line */
  return !isEnterpriseTier.value;
});
const isViewOnly = computed(() => {
  return isNotSupernode.value || isViewOnlyMode.value;
});

async function handleSavePartnerVisibilityModal() {
  /**
   * Setting the Partner Access value to View or Per-partner-view
   * based on the user choice
   */
  if (permissionSet.value['partner-visibility'] && selectedPermission.value) {
    permissionSet.value['partner-visibility'].id =
      `${selectedPermission.value}:partner-visibility`;
    permissionSet.value['partner-visibility'].level = selectedPermission.value;
  }

  /**
   * Downgrading access level to permission depending on "Partner access - Manage":
   * manage > view
   * view > no-access
   */
  Object.keys(WARNING_PERMISSIONS).forEach((warningPerm) => {
    const permName = warningPerm.split(':')[1];
    const permLevel = warningPerm.split(':')[0] as PermissionLevel;
    const downgradedPermLevel = permLevel === 'manage' ? 'view' : 'no-access';
    if (permName && permissionSet.value[permName]) {
      permissionSet.value[permName].id = `${downgradedPermLevel}:${permName}`;
      permissionSet.value[permName].level = downgradedPermLevel;
    }
  });

  /* Closing and saving the change from the warning modal action only */
  handleShowPartnerVisibilityModal(true);
  loading.value = true;
  await saveRole();
  loading.value = false;
}

function setRolePermission(
  resourceName: string,
  permissionLevel: PermissionLevel | null,
) {
  /**
   * If this is the Partner Access (partner-visibility) select:
   * We check if the former permission level is "manage",
   * because if the user change it to "view" or "per-partner-view",
   * we need to trigger a warning modal before doing any change.
   */
  const isRolePartnerVisibilityPermissionSetToManage =
    !!role.value.permissions.find((p) => p.id === 'manage:partner-visibility');
  const isChangingPartnerVisibilityPermissionFromManage =
    resourceName === 'partner-visibility' &&
    isRolePartnerVisibilityPermissionSetToManage &&
    permissionLevel &&
    ['view', 'per-partner-view'].includes(permissionLevel) &&
    !isOpenPartnerVisibilityModal.value;

  if (isChangingPartnerVisibilityPermissionFromManage) {
    isOpenPartnerVisibilityModal.value = true;
    return;
  }

  /**
   * Updating the list of permissions to reflect the change.
   * This is for every changes but from "Partner access - Manage"
   */
  if (permissionSet.value[resourceName] && permissionLevel) {
    permissionSet.value[resourceName].level = permissionLevel;
    permissionSet.value[resourceName].id =
      `${permissionLevel}:${permissionSet.value[resourceName].resource}`;
  }
}

function getPermissionLevel(resource: { name: string }) {
  if (!resource?.name) return null;
  const permissionId = permissionSet.value[resource.name]?.id;
  if (!permissionId) return null;
  return permissionDetails.value[permissionId]?.level || null;
}

function handleShowPartnerVisibilityModal(hasConfirmed = false) {
  if (!hasConfirmed) selectedPermission.value = 'manage';
  isOpenPartnerVisibilityModal.value = !isOpenPartnerVisibilityModal.value;
}
</script>

<style lang="pcss">
.c-role-detail__permissions {
  max-height: 315px;
  min-height: 170px;
  overflow-y: scroll;
  @apply mb-16;
}

.permission-item {
  @apply text-base mb-16 flex justify-between items-center;
}

.u-grey-box {
  border-radius: 3px;
  @apply border border-neutral-200;
  &:focus {
    @apply border-brand-teal;
  }
  &:focus-within {
    @apply border-brand-teal;
  }
}

.c-edit-role__permission-detail {
  @apply flex flex-col border border-neutral-200 p-20;
}
.c-edit-role__permission-select {
  @apply bg-neutral-200 w-120 h-25 flex justify-between items-center;
  border-radius: 2px;
}

.permissions-visibility-selector {
  @apply px-4;
}

.permissions-visibility-selector__confirm-modal {
  &.o-bitts-modal.bitts-modal--confirm .o-bitts-modal__content {
    @apply flex text-wrap;
  }
}

.population-sharing-visibility-selector__description {
  @apply text-neutral-text-weak text-wrap mr-8;
}
.ant-select-selection-item {
  @apply text-neutral-text-strong;
  .population-sharing-visibility-selector__description {
    @apply hidden;
  }
}
.ant-select-disabled {
  .ant-select-selection-item {
    @apply text-neutral-text-weak opacity-50;
  }
}
.ant-select-item-option-selected {
  @apply flex items-center gap-16;
}

.ant-select-item-option-state > svg {
  @apply w-16 !important;
}
</style>
